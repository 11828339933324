import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <>
            <footer className="main_footer" role="contentinfo">
                <div className="container quote_hide_footer">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer_menu_row">
                                <div className="footer_menu_item">
                                    <ul>
                                        <li><Link to={"/"}>Investor Deck</Link></li>
                                        <li><Link to={"/"}>Wallet</Link></li>
                                        <li><Link to={"/"}>Case</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer_menu_row2">
                        <ul>
                            <li><Link to={"/"}>Terms of Use</Link></li>
                            <li><Link to={"/"}>Privacy Policy</Link></li>
                            <li><Link to={"/"}>AML Policy</Link></li>
                            <li><Link to={"/"}>Risk Warning Statement</Link></li>
                            <li><Link to={"/"}>Cookie Policy</Link></li>
                            <li><Link to={"/"}>Careers</Link></li>
                            <li><Link to={"/"}>FAQ</Link></li>
                            <li><Link to={"/"}>Support</Link></li>
                        </ul>
                    </div>
                    <div className="container quote_hide_footer">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="get_in_touch">
                                    <div className="get_in_touch_row">
                                        {/* <div className="address_row">
                                            <p><i className="fa-solid fa-location-dot"></i> Romana Dmowskiego 3 / 9, 50-203 Wrocław, Polska</p>
                                        </div> */}
                                        <div className="contact_social">
                                            <div className="contact_details">
                                                <Link to={"mailto:help@getflexo.co"}><i className="fa-solid fa-envelope"></i>help@getflexo.co</Link>
                                                <Link to={"mailto:help@getflexo.co"}><i className="fa-solid fa-envelope"></i>help@getflexo.co</Link>
                                            </div>
                                            <div className="social_media">
                                                <span>
                                                    <Link to={"/"} title="facebook" target="_blank"><i className="fa-brands fa-telegram"></i></Link>
                                                    <Link to={"/"} title="linkedin" target="_blank"><i className="fa-brands fa-linkedin-in"></i></Link>
                                                    <Link to={"/"} title="linkedin" target="_blank"><i className="fa-brands fa-x-twitter"></i></Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </footer >
        </>
    );
};

export default Footer;
